import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { object, node } from 'prop-types';
import mockStyleColor from '../../__test__/__mocks__/cpa/cpa-merged.json';
import { setSearchValueAndType } from './cpa.utils';
import { fetchCPAData } from './cpa.axios';

import { SimWebContext } from '../SimWeb.provider';
import { tutorialContext } from '../Tutorial.provider';

export const CPAContext = createContext({});

const CPAProvider = ({ children, mockedValue }) => {
  const { Provider } = CPAContext;
  const {
    storeId, iso2Country, store, region, storeInfo, storeConfig,
  } = useContext(SimWebContext);
  const { startTutorial } = useContext(tutorialContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState(null);
  const [isIIWidget, setIsIIWidget] = useState(false);
  const [isUnitCountEnabled, setIsUnitCountEnabled] = useState(false);

  const eddEnabled = storeConfig?.isEDDEnabled?.value;

  useEffect(() => {
    setData(null);
    setError(null);

    const fetchData = async (searchParam) => {
      const { searchValue, searchType } = setSearchValueAndType(searchParam, isIIWidget);

      if (searchValue) {
        setLoading(true);
        try {
          const cpaData = await fetchCPAData(
            storeId, store, searchType, searchValue, iso2Country, region, isUnitCountEnabled, storeInfo?.isEMEA, eddEnabled,
          );

          setData(cpaData);
          setLoading(false);
        } catch (err) {
          setError(err?.message);
          setLoading(false);
        }
      }
      if (startTutorial) setData([mockStyleColor[1]]);
    };
    fetchData(searchParam);
  }, [searchParam, startTutorial, isUnitCountEnabled]);

  return (
    <Provider
      value={mockedValue ?? {
        data,
        error,
        loading,
        setLoading,
        getProducts: (param, fromII = false, isUnitCountEnabled = false) => {
          setSearchParam(param);
          setIsIIWidget(fromII);
          setIsUnitCountEnabled(isUnitCountEnabled);
        },
      }}
    >
      {children}
    </Provider>
  );
};

CPAProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

CPAProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default CPAProvider;
