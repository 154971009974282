import React from 'react';
import writeXlsxFile from 'write-excel-file';
import { getRfidTableHeader } from '../../../components/RFIDTableHeader';
import { getLocalizedNumber } from '../utils';
import getLocalizedPercent from '../../../utils/getLocalizedPercent';
import getFormattedPrice, { formatCurrency } from '../../../utils/getFormattedPrice';
import { SimDateTime } from '../../../utils/datetime';

/**
 * A helper function to get the columns for the table
 * @param {string} id - the report id
 * @param {boolean} rfidEnabled - is rfid enabled in the store
 * @param {boolean} isOffsiteEnabled - is rfid enabled in the store
 * @param {boolean} isOffsiteInactive - is offsite enabled in the store
 * @param {string} locale - the locale of the store
 * @param {string} currencyCode - the currency code of the store
 * @param {string} aggregatedBy - the aggregation type - genderAge or retailCategory
 * @param {array} tableFilters - a list of filters applied to the table
 * @param {function} getMessage - the function to get the localized message
 */
export const getColumns = (
  id, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, locale, currencyCode, aggregatedBy, tableFilters, getMessage,
) => {
  const rfidColumn = getRfidTableHeader('rfidSoh', getMessage('rfidStock'), id, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, false, true);
  const [categoryFilter, divisionFilter] = tableFilters;

  const columns = [
    {
      name: 'productCode',
      label: getMessage('styleColor'),
    },
    {
      name: 'description',
      label: getMessage('description'),
    },
    {
      name: 'division',
      label: getMessage('division'),
      options: {
        ...(divisionFilter ? { filterList: [divisionFilter] } : { filterList: [] }),
      },
    },
    {
      name: 'genderAge',
      label: getMessage('genderAge'),
      options: {
        display: 'excluded',
        ...((categoryFilter && aggregatedBy === 'genderAge') ? { filterList: [categoryFilter] } : { filterList: [] }),
      },
    },
    {
      name: 'retailCategory',
      label: getMessage('retailCategory'),
      options: {
        ...((categoryFilter && aggregatedBy === 'retailCategory') ? { filterList: [categoryFilter] } : { filterList: [] }),
      },
    },
    {
      name: 'currentPrice',
      label: getMessage('currentPrice'),
      options: {
        customBodyRender: value => (
          <span>{getFormattedPrice(value, locale, currencyCode)}</span>
        ),
      },
    },
    {
      name: 'valueVariance',
      label: getMessage('valueVariance'),
      options: {
        customBodyRender: value => (
          <span className={value !== 0 ? 'scan-total-value-variance-value' : ''}>{getFormattedPrice(value, locale, currencyCode)}</span>
        ),
      },
    },
    {
      name: 'scanned',
      label: getMessage('scanned'),
      options: {
        customBodyRender: value => getLocalizedNumber(locale, value),
      },
    },
    {
      name: 'expected',
      label: getMessage('expected'),
      options: {
        customBodyRender: value => getLocalizedNumber(locale, value),
      },
    },
    {
      name: 'missing',
      label: getMessage('missing'),
      options: {
        customBodyRender: value => getLocalizedNumber(locale, value),
      },
    },
    {
      name: 'extra',
      label: getMessage('extra'),
      options: {
        customBodyRender: value => getLocalizedNumber(locale, value),
      },
    },
    {
      name: 'accuracy',
      label: getMessage('accuracy'),
      options: {
        customBodyRender: value => getLocalizedPercent(locale, value),
      },
    },
    rfidColumn,
    {
      name: 'totalRfidSoh',
      label: 'Total RFID SOH',
    },
  ];

  return columns;
};

/**
 * Creates headers for excel download
 * @param {*} columns columns to create headers for
 */
export const createDownloadHeaders = (columns) => {
  const headerRow = [];
  columns.map(column => headerRow.push({ value: column.label.toUpperCase(), fontWeight: 'bold' }));
  return headerRow;
};

/**
 * Calculates the maximum width for each column in a 2D array of data.
 *
 * @param {Array<Array<any>>} data - A 2D array where each sub-array represents a row of data.
 * @returns {Array<number>} An array of numbers representing the maximum width of each column.
 */
export const calculateColumnWidths = (data) => {
  const widths = data[0].map((_, colIndex) => Math.max(...data.map(row => row[colIndex].value.toString().length)));
  return widths;
};

/**
 * A helper function to get the options for the table
 * @param {function} onRowClick - the function to call when a row is clicked
 * @param {boolean} isPrintingEnabled - is printing enabled
 * @param {array} tableFilters - a list of filters applied to the table
 * @param {string} varianceType - the type of variance - missing or extra
 * @param {function} getMessage - the function to get the localized message
 */
export const getTableOptions = (onRowClick, isPrintingEnabled, tableFilters, varianceType, getMessage, locale, currencyCode) => {
  const [categoryFilter, divisionFilter] = tableFilters;
  return {
    onRowClick: (rowData, { dataIndex }) => onRowClick(rowData, dataIndex),
    selectableRows: 'none',
    print: isPrintingEnabled,
    responsive: 'standard',
    rowsPerPage: 100,
    rowsPerPageOptions: [15, 25, 50, 100, 1000],
    filter: true,
    download: true,
    onDownload: (_buildHead, _buildBody, newColumns, newData) => {
      try {
        const valueVarianceIndex = newColumns.findIndex(column => column.name === 'valueVariance');
        const currentPriceIndex = newColumns.findIndex(column => column.name === 'currentPrice');

        newData.sort((a, b) => {
          const valueA = parseFloat(a.data[valueVarianceIndex]) || 0;
          const valueB = parseFloat(b.data[valueVarianceIndex]) || 0;
          return valueB - valueA;
        });

        const dataRows = newData.map(row => row.data.map((value, index) => {
          let currentValue = value;

          if (index === valueVarianceIndex || index === currentPriceIndex) {
            currentValue = formatCurrency(value ? parseFloat(value) : 0, locale, currencyCode);
          }

          return { value: currentValue?.toString() ?? '', type: String };
        }));

        const headerRow = createDownloadHeaders(newColumns);
        const columnWidths = calculateColumnWidths([...[headerRow], ...dataRows]);
        const columns = columnWidths.map(width => ({ width: width + 2 }));
        writeXlsxFile([headerRow, ...dataRows], { columns, fileName: `ScanReport-${SimDateTime.toUtcISO()}.xlsx` });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      return false; // cancels default datatable download logic
    },
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    textLabels: {
      body: {
        noMatch: getMessage('noData'),
      },
    },
    sortOrder: {
      ...((!categoryFilter && !divisionFilter) && { name: 'valueVariance', direction: 'desc' }),
      ...(((categoryFilter || divisionFilter) && varianceType === 'missing') && { name: 'missing', direction: 'desc' }),
      ...(((categoryFilter || divisionFilter) && varianceType === 'extra') && { name: 'extra', direction: 'desc' }),
    },
    fixedHeader: true,
    tableBodyMaxHeight: '625px',
  };
};
