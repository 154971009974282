/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable max-len */
import React from 'react';
import writeXlsxFile from 'write-excel-file';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { isArray, orderBy } from 'lodash';
import { Tooltip, Button } from '@mui/material';
import getFormattedPrice from '../../utils/getFormattedPrice';
import PickListToolBar from '../sizeCompliance/picklistModal/PickListToolBar';
import createSOHFilter from '../../components/customTableFilters/CustomSOHFilter';
import { getRfidTableHeader } from '../../components/RFIDTableHeader';
import { sortByNumberByData } from '../../utils/sort/sortByNumber';
import { styleManagementFilters } from './styleManagementConstants';
import { buildColumnIndexMappings } from '../../utils/buildColumnIndexMappings';
import filterDataTableData, { filterRowData } from '../../utils/filterDataTableData';
import { sortBySeason } from '../../utils/sort/sortBySeason';
import DIVISIONS from '../../constants/divisions';
import { Shoe } from '../../assets/Svgs';
import { SimDateTime } from '../../utils/datetime';

/**
 * Gets the Style Management filters
 * @param {boolean} rfidEnabled - is rfid enabled
 * @param {array} buttonFilters - a list of active button filters
 */
export const getStyleManagementFilters = (rfidEnabled, buttonFilters) => ([styleManagementFilters.rfidEnabled(rfidEnabled), ...buttonFilters]);

/**
  * creates an array of error messages
  * @param {Array} styleManagementErrors - products
*/
export const getErrorMessages = (styleManagementErrors) => {
  if (!styleManagementErrors || styleManagementErrors.length === 0) return null;
  let messages = [];

  messages = messages.concat(isArray(styleManagementErrors) ? styleManagementErrors?.map(error => error.message) : JSON.stringify(styleManagementErrors));
  return messages;
};

/**
 * Handles the sorting off of SOH by default on page load
 * @param {object} data – the full product detail object
 */
export const sortDefaultBySOH = (data) => {
  if (!data) return [];

  const sortedValue = [...data];
  sortedValue?.sort((a, b) => {
    if (a?.fiscalSoh > b?.fiscalSoh) {
      return -1;
    }
    if (a?.fiscalSoh === '-' && b?.fiscalSoh !== '-') {
      return -1;
    }
    return 0;
  });
  return sortedValue;
};

/**
  * sorts and object of arrays with given prop
  * @param {string} prop - property to sort by
*/
export const byProperty = (prop) => (a, b) => {
  if (typeof a[prop] === 'number') {
    return (a[prop] - b[prop]);
  }
  return ((a[prop] < b[prop]) ? -1 : ((a[prop] > b[prop]) ? 1 : 0));
};

export const rfidColumn = (getMessage, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, rfidSohList) => getRfidTableHeader(
  'rfidSoh', getMessage('rfidStock'), 'style-management', rfidEnabled, isOffsiteEnabled, isOffsiteInactive, false, true, rfidSohList, getMessage, true,
);

export const getColumns = (getMessage, isIOS, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, isEMEA, sohList, rfidSohList, tableFilters, locale, currencyCode) => {
  const columns = [
    {
      name: 'productCode',
      label: getMessage('style-color'),
      options: {
        filterType: 'textField',
      },
    },
    {
      name: 'description',
      label: getMessage('description'),
      options: {
        display: !isIOS,
        filterType: 'textField',
      },
    },
    {
      name: 'genderAge',
      label: getMessage('gender'),
      options: { filterType: 'multiselect' },
    },
    {
      name: 'division',
      label: getMessage('division'),
      options: { filterType: 'multiselect' },
    },
    {
      name: 'retailCategory',
      label: getMessage('retailCategory'),
      options: { filterType: 'multiselect' },
    },
    {
      name: 'binInfo',
      label: getMessage('bin'),
      options: { filter: true },
    },
    {
      name: 'price',
      label: getMessage('price'),
      options: {
        display: !isIOS,
        filter: true,
        customBodyRender: value => (getFormattedPrice(value, locale, currencyCode)),
      },
    },
    {
      name: 'fiscalSoh',
      label: getMessage('soh'),
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          'data-testid': 'columnStep',
        }),
        filterType: 'custom',
        ...createSOHFilter({ getMessage, sohRange: sohList, filterType: 'soh' }),
      },
    },
    rfidColumn(getMessage, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, rfidSohList),
    {
      name: 'season',
      label: getMessage('season'),
      options: {
        display: !isIOS,
        filter: true,
        filterType: 'multiselect',
      },
    },
    // {
    //   name: 'dotcomAvailability',
    //   label: 'Nike.com',
    //   options: {
    //     filter: true,
    //     customBodyRender: value => (value ? <CheckCircleIcon color="success" /> : '-')
    //   },
    // },
    {
      name: 'salesFloor',
      label: getMessage('salesFloor'),
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          if (tableMeta?.rowData[10] === 'On Display') {
            return (
              <Tooltip
                title="This item is on display."
                placement="right"
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -50],
                        },
                      },
                    ],
                  },
                }}
              >
                <Button
                  disableRipple
                  data-testid="on-display-icon"
                  style={{
                    color: 'black',
                    justifyContent: 'start',
                    padding: 0,
                  }}
                  sx={{ '&.MuiButtonBase-root:hover': { backgroundColor: 'transparent' } }}
                >
                  <Shoe />
                </Button>
              </Tooltip>
            );
          }
          if (tableMeta?.rowData[10] === 'On The Salesfloor') return <CheckCircleIcon color="success" />;
          return DIVISIONS.isEquipment(tableMeta?.rowData[3]) ? value : '-';
        },
      },
    },
    {
    // used to filter the table since salesFloor is a component
      name: 'salesFloorFilter',
      label: getMessage('salesFloor'),
      options: {
        display: 'excluded',
        filter: true,
      },
    },
    {
      name: 'daysMissing',
      label: getMessage('daysMissing'),
      options: { filter: true },
    },
    {
      name: 'inboundUnits',
      label: getMessage('inboundUnits'),
      options: { filter: true },
    },
    {
      name: 'lastReceived',
      label: getMessage('lastReceived'),
      options: { filter: true, display: isEMEA },
    },
    {
      name: 'agedInventory',
      label: getMessage('agedInventory'),
      options: {
        filter: true,
        display: true,
        customBodyRender: value => (value ? <CheckCircleIcon color="success" /> : '-'),
      },
    },
    {
      name: 'newStyle',
      label: getMessage('newStyle'),
      options: {
        filter: true,
        display: true,
        customBodyRender: value => (value ? <CheckCircleIcon color="success" /> : '-'),
      },
    },
    {
      name: 'topSeller',
      label: getMessage('topSeller'),
      options: {
        filter: true,
        display: true,
        customBodyRender: value => (value ? <CheckCircleIcon color="success" /> : '-'),
      },
    },
  // TODO: enable these when reporting metrics are ready
  // {
  //   name: 'unitsSold',
  //   label: getMessage('unitsSold'),
  //   options: { filter: true, display: false },
  // },
  ];

  // apply state table filters to the columns
  const updatedColumns = columns.map(column => {
    if (column.options) {
      return {
        ...column,
        options: {
          ...column.options,
          filterList: tableFilters?.find(filter => filter.name === column.name)?.value || [],
        },
      };
    }
    return column;
  });

  return updatedColumns;
};

/**
 * Creates headers for excel download
 * @param {*} columns columns to create headers for
 * @param {*} offsiteEnabled - is offsite enabled
 */
export const createDownloadHeaders = (columns, offsiteEnabled) => {
  const headerRow = [];
  columns.map(column => {
    if (column.name === 'rfidSoh') {
      const rfidCols = [
        { value: 'FOH SOH', fontWeight: 'bold' },
        { value: 'BOH SOH', fontWeight: 'bold' },
      ];
      if (offsiteEnabled) {
        rfidCols.push({ value: 'Offsite SOH', fontWeight: 'bold' });
      }
      return rfidCols.map(rfidCol => headerRow.push(rfidCol));
    }
    return headerRow.push({ value: column.label.toUpperCase(), fontWeight: 'bold' });
  });
  return headerRow;
};

/**
 * Update the data for download
 * @param {Object} rows the data to download
 * @param {object} mapping the column-index mapping
 * @param {boolean} offsiteEnabled - is offsite enabled
 */
export const getDownloadData = (rows, columns, offsiteEnabled) => {
  const toDownload = [];
  const mapping = buildColumnIndexMappings(columns);
  const nullStock = offsiteEnabled ? '-/-/-' : '-/-';
  const booleanColumns = [mapping.agedInventory, mapping.newStyle, mapping.topSeller];

  rows.map(item => {
    const newStyle = [];
    item.data.map((data, index) => {
      if (booleanColumns.includes(index)) {
        return newStyle.push(!data || data === '-' ? 'NO' : 'YES');
      }

      if (index === mapping.salesFloor) {
        if (data === 'On The Salesfloor') return newStyle.push('YES');
        if (data === 'On Display') return newStyle.push('DISPLAY');
        return newStyle.push(data === 'N/A' ? 'N/A' : 'NO');
      }

      if (index === mapping.rfidSoh) {
        data = (!data || data === '-') ? nullStock : data;
        const splitRFID = data.split('/');
        return splitRFID.map(rfid => newStyle.push(rfid));
      }

      return newStyle.push(data);
    });
    toDownload.push({ data: newStyle });
  });

  return toDownload;
};

export const getSortColumn = (buttonFilters) => {
  const filterToColumnMapping = {
    agedInventory: 'agedInventory',
    missingStyles: 'daysMissing',
    newItem: 'newStyle',
    topSeller: 'topSeller',
    inboundUnits: 'inboundUnits',
  };

  const filteredButtonFilters = buttonFilters?.filter(filter => filter.name !== 'binned' && filter.name !== 'unbinned');
  return (filteredButtonFilters?.length === 1) ? filterToColumnMapping[filteredButtonFilters?.[0]?.name] : 'fiscalSoh';
};

export const getTableOptions = (columns, currencySymbol, rows, setModalStyleColor, setIsOpen, setTableIndex, getMessage,
  isPrintingEnabled, tableFilters, setTableFilters, setTableFilteredData, offsiteEnabled, setPicklistToCreate,
  selectedRows, setSelectedRows, isPicklistComplete, isCreated, setPicklistComplete, setLoading, picklistEnabled, buttonFilters) => {
  const isColumn = (name, index) => Object.values(columns).findIndex(column => column.name === name) === index;
  const useSortByNumber = ['rfidSoh', 'fiscalSoh', 'inboundUnits', 'daysMissing', 'price'];
  const sortColumn = getSortColumn(buttonFilters);

  const MAX_SELECTABLE_ROW_COUNT = 100;

  // TODO: refactor using getBaseTableOptions...sorting is not working.
  const options = {
    selectableRows: picklistEnabled ? 'multiple' : 'none',
    rowsPerPage: 100,
    rowsPerPageOptions: [15, 25, 50, 100, 1000],
    print: isPrintingEnabled,
    responsive: 'standard',
    download: true,
    sort: true,
    sortOrder: { name: sortColumn, direction: 'desc' },
    filter: true,
    setFilterChipProps: (_colIndex, _colName, _data) => ({
      color: 'error',
      variant: 'outlined',
    }),
    tableBodyHeight: '625px',
    textLabels: {
      body: {
        noMatch: getMessage('noData'),
      },
      toolbar: {
        downloadCsv: 'Download XLS',
      },
    },
    onRowClick: (rowData, { rowIndex }) => {
      const styleColor = rowData[0];
      setModalStyleColor(styleColor);
      setIsOpen(true);
      setTableIndex(rowIndex);
    },
    rowsSelected: isPicklistComplete && !isCreated ? [] : selectedRows,
    onRowSelectionChange: (rowsSelected, allRows) => {
      setPicklistComplete(false);
      setLoading(true);
      const selectable = allRows.slice(0, MAX_SELECTABLE_ROW_COUNT);
      setSelectedRows(selectable);
    },
    customSort: (data, dataIndex, direction) => {
      const useDashSorting = useSortByNumber.filter(item => isColumn(item, dataIndex)).length > 0;
      const useSeasonSorting = ['season'].filter(item => isColumn(item, dataIndex)).length > 0;
      const filteredData = filterRowData(data, tableFilters);
      let sortedData;

      if (useDashSorting) {
        sortedData = filteredData && sortByNumberByData(filteredData, dataIndex, direction, currencySymbol);
      }
      if (useSeasonSorting) sortedData = filteredData && sortBySeason(filteredData, dataIndex, direction);

      sortedData = orderBy(filteredData, _ => _.data[dataIndex], [`${direction}`]);
      setTableFilteredData(sortedData);
      return sortedData;
    },
    customToolbarSelect: (selectedRows, displayData) => (
      picklistEnabled && <PickListToolBar createPickList={() => setPicklistToCreate(selectedRows, displayData)} />
    ),
    onFilterChange: (changedColumn, filterList, _type, changedColumnIndex, displayData) => {
      const { filteredData, newTableFilters } = filterDataTableData(rows, changedColumn, filterList, changedColumnIndex, tableFilters);
      setTableFilters(newTableFilters);
      setTableFilteredData(filteredData);
      setTableIndex(0);
    },
    onDownload: (_buildHead, _buildBody, newColumns, newData) => {
      const data = getDownloadData(newData, newColumns, offsiteEnabled);
      const headerRow = createDownloadHeaders(newColumns, offsiteEnabled);
      const dataRows = data.map(row => row.data.map(value => ({ value: value?.toString() ?? '', type: String })));

      try {
        writeXlsxFile([headerRow, ...dataRows], { fileName: `Style-Management-${SimDateTime.toUtcISO()}.xlsx` });
      } catch (error) {
        console.error(error);
      }

      return false; // cancels default datatable download logic
    },
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  return options;
};

/**
 * Util that takes state/utils/configs & handles setting/changing steps in the turorial when clicking through the steps
 * @param {boolean} startTutorial - checks if we're in tutorial mode
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {boolean} restartTutorial - a value used to cancel out any state since the tutorial is no longer active
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 * @param {boolean} isOpen - a value that represents the table row/shipdate was selected - meaning the modal was mounted
 * @param {func} setIsOpen - a function that opens/closes the modal
 * @param {func} setStepIndex - a function that handles setting the step index
 */
export const setStyleManagementTutorialSteps = (
  startTutorial, stepIndex, restartTutorial, isbackClicked, isOpen, setIsOpen, setStepIndex,
) => {
  if (startTutorial) {
    if (stepIndex === 7 && isbackClicked) {
      setIsOpen(false);
    }
    if (stepIndex === 8 && !isOpen) {
      document.querySelector('[data-testid="MUIDataTableBodyRow-0"]')?.click(); // click the single row
      setTimeout(() => {
        setStepIndex(8);
      }, 500);
    }
    if (stepIndex === 11 && !isOpen && isbackClicked) {
      setIsOpen(true);
      setTimeout(() => {
        setStepIndex(11);
      }, 500);
    }
    if (stepIndex === 12 && isOpen) {
      setIsOpen(false);
    }
  }
  if (restartTutorial) {
    if (isOpen) setIsOpen(false);
  }
};
