import React from 'react';
import { array, func } from 'prop-types';
import MUIDataTable from 'mui-datatables';
import ExpandableRows from './ExpandableRows';

import '../AdjustmentsPage.css';

const PrinterPage = ({
  columns, data, getMessage,
}) => (
  <div className="print-source" data-testid="print-source">
    <MUIDataTable
      data={data}
      columns={columns}
      options={{
        expandableRows: true,
        pagination: false,
        responsive: 'standard',
        rowsExpanded: Array.from(Array(data?.length).keys()),
        expandableRowsOnClick: true,
        expandableRowsHeader: data?.length > 0,
        renderExpandableRow: (rowData, { dataIndex }) => (
          <tr>
            <td colSpan={rowData.length + 1}>
              <ExpandableRows
                data={data[dataIndex]}
                getMessage={getMessage}
              />
            </td>
          </tr>
        ),
      }}
    />
  </div>
);

PrinterPage.propTypes = {
  columns: array,
  data: array,
  getMessage: func.isRequired,
};

PrinterPage.defaultProps = {
  columns: [],
  data: [],
};

export default PrinterPage;
