import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ChevronButton from '../../../components/ChevronButton';
import Toggle from '../../../components/toggle/Toggle';
import BaseViewPage from '../../baseView/BaseViewPage';
import { baseDialogWrapper as Dialog } from '../../baseView/BaseViewPageUtils';
import DetailsWidgetContainer from './detailsWidget/DetailsWidgetContainer';
import GroupedBarChartWithLegend from '../../../components/visualSummaries/groupedBarChart/GroupedBarChartWithLegend';
import ProductCard from './products/ProductCard';
import { getAggregatedBy } from '../../../components/toggle/GenderCategoryToggle';
import { getColumns, getTableOptions } from './scanDetails.utils';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { ScanDetailsContext } from './ScanDetails.provider';
import { ScanTimerContext } from '../../../context/ScanTimer.provider';
import { CPAContext } from '../../../context/cpa/CPA.provider';

import './ScanDetailsPage.css';

const ScanDetailsPage = () => {
  const {
    currencyCode, formatMessage, getMessage, isOffsiteEnabled, isOffsiteInactive, isPrintingEnabled,
    locale, nikeStoreRoles, SimDateTime, storeConfig,
  } = useContext(SimWebContext);
  const {
    id, data, loading, error,
  } = useContext(ScanDetailsContext);
  const { isScanNotProccessing, scanProgress } = useContext(ScanTimerContext);
  const { getProducts } = useContext(CPAContext);

  const history = useHistory();

  const [selectedToggle, setSelectedToggle] = useState('genderAge');
  const [isOpened, setIsOpened] = useState(false);
  const [tableIndex, setTableIndex] = useState(null);
  const [scanProductDetailData, setScanProductDetailData] = useState(null);
  const [varianceType, setVarianceType] = useState('missing');
  const [tableFilters, setTableFilters] = useState([]);

  const accuracySubmissionThreshold = storeConfig?.accuracySubmissionThreshold?.value;
  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const pageTitle = 'scanReport';

  const toggles = {
    gender: {
      name: 'genderAge',
      label: getMessage('gender'),
      aggregatedBy: 'genderAge',
    },
    category: {
      name: 'retailCategory',
      label: getMessage('category'),
      aggregatedBy: 'retailCategory',
    },
  };

  useEffect(() => {
    const productCode = data?.items?.[tableIndex]?.productCode;
    getProducts(productCode);
  }, [tableIndex]);

  const customSummaryContainer = useMemo(() => {
    if (!data || isEmpty(data)) return null;

    const toggle = (
      <Toggle
        hasSeveralToggles
        toggleLabelsArray={Object.values(toggles)}
        selected={selectedToggle}
        setSelected={value => { setSelectedToggle(value); }}
        toggleByLabel
        toggleDirection="vertical"
      />
    );

    const aggregatedBy = getAggregatedBy(toggles, selectedToggle);

    return (
      <div className="scan-details-summary-container" data-testid="scan-details-summary-container">
        <DetailsWidgetContainer
          accuracySubmissionThreshold={accuracySubmissionThreshold}
          data={data}
          formatMessage={formatMessage}
          getMessage={getMessage}
          isOffsiteEnabled={isOffsiteEnabled}
          isScanNotProccessing={isScanNotProccessing}
          locale={locale}
          nikeStoreRoles={nikeStoreRoles}
          scanProgress={scanProgress}
          SimDateTime={SimDateTime}
        />
        <GroupedBarChartWithLegend
          id={id}
          data={data.graphAggregations}
          loading={loading}
          error={!!error}
          aggregatedBy={aggregatedBy}
          toggle={toggle}
          varianceType={varianceType}
          setVarianceType={setVarianceType}
          tableFilters={tableFilters}
          setTableFilters={setTableFilters}
        />
      </div>
    );
  }, [accuracySubmissionThreshold, data, currencyCode, error, getMessage, isOffsiteEnabled,
    locale, loading, scanProgress, selectedToggle, toggles, nikeStoreRoles]);

  const onRowClick = (rowData, dataIndex) => {
    const productCode = rowData[0];

    if (!productCode) return;
    setScanProductDetailData(data.items?.find(item => item.productCode === productCode));
    setTableIndex(dataIndex);
    setIsOpened(true);
  };

  const columns = useMemo(() => getColumns(
    id, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, locale, currencyCode, selectedToggle, tableFilters, getMessage,
  ), [rfidEnabled, isOffsiteEnabled, isOffsiteInactive, locale, tableFilters, getMessage, currencyCode]);

  const tableOptions = useMemo(() => getTableOptions(
    onRowClick, isPrintingEnabled, tableFilters, varianceType, getMessage, locale, currencyCode,
  ), [isPrintingEnabled, tableFilters, varianceType, getMessage, locale, currencyCode]);

  const dialogModal = useMemo(() => {
    const content = (
      <ProductCard
        index={tableIndex}
        setIsOpen={setIsOpened}
        rowCount={data?.items?.length}
        scanProductDetailData={scanProductDetailData}
        scanType={data?.scanType}
        setIndex={setTableIndex}
      />
    );
    return <Dialog id={id} isOpen={isOpened} setIsOpen={setIsOpened} content={content} />;
  }, [isOpened, tableIndex, scanProductDetailData, data?.length]);

  return (
    <>
      <BaseViewPage
        id={id}
        title={pageTitle}
        storeConfigValue="isRfidScanReportEnabled"
        loading={loading}
        error={error}
        details={{
          options: tableOptions,
          columns,
          rows: data?.items,
        }}
        customSummaryContainer={customSummaryContainer}
        dialog={dialogModal}
      />
      <div className="scan-details-table-view-footer">
        <ChevronButton
          type="button"
          onClick={() => { history.push('/scanSummary'); }}
          buttonText={getMessage('back')}
          data-testid="scan-details-back-button"
          extraClasses="scan-details-back-button"
          chevron="left"
          style={{ padding: '0 24px' }}
        />
      </div>
    </>
  );
};

export default ScanDetailsPage;
