import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { node, object } from 'prop-types';
import { fetchScanDetailsData } from '../scanReport.axios';
import { SimWebContext } from '../../../context/SimWeb.provider';

export const ScanDetailsContext = createContext({});

const ScanDetailsProvider = ({ children, mockedValue }) => {
  const { sessionId, endDate } = useParams();
  const { Provider } = ScanDetailsContext;
  const { isOffsiteEnabled } = useContext(SimWebContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const id = 'scanDetailsReport';

  useEffect(() => {
    const fetchScan = async () => {
      setLoading(true);

      try {
        const response = await fetchScanDetailsData(sessionId, endDate, isOffsiteEnabled);
        setData(response);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchScan(sessionId);
  }, []);

  return (
    <Provider value={mockedValue ?? {
      id,
      data,
      error,
      loading,
    }}
    >
      {children}
    </Provider>
  );
};

ScanDetailsProvider.propTypes = {
  children: node,
  mockedValue: object,
};

ScanDetailsProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

export default ScanDetailsProvider;
