/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
import { isEmpty, cloneDeep } from 'lodash';
import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { buildURLParams } from './cpa.utils';

/**
 * Fetch product data from CPA Facade API by list of gtins, styleColors or styleCodes
 * @param {string} storeId the store id
 * @param {string} store the store country and number. Required if isUnitCountEnabled is true. e.g., USA-123
 * @param {string} searchType the product search type. e.g., productCode, style or gtin
 * @param {string | array} serachValue the productCode, style or gin value
 * @param {string} merchGroup the iso2Country from storeView
 * @param {region} region the AWS region. Required if isUnitCountEnabled is true. e.g., us-east-1
 * @param {boolean} isUnitCountEnabled to retrieve inbound unit counts if true
 * @param {boolean} isEMEA is store's geo location in EMEA
 * @param {boolean} eddEnabled is store has EDD enabled
 */
export const fetchCPAData = async (storeId, store, searchType, searchValue, merchGroup, region, isUnitCountEnabled, isEMEA, eddEnabled) => {
  if (!storeId || !searchType || !searchValue || !merchGroup) return null;

  const headers = { region: global.SIM_REGION };
  const urlParams = `?storeId=${storeId}&storeNumber=${store}&searchType=${searchType}&searchValue=${searchValue}&merchGroup=${merchGroup}&region=${region}&isInboundUnitCountEnabled=${isUnitCountEnabled}&isEMEA=${isEMEA}&eddEnabled=${eddEnabled}`;

  try {
    const cpaUrl = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.CPA_FACADE.url}${urlParams}`;
    const cpaResponse = await generalAxiosRequest('GET', cpaUrl, endpoints.CPA_FACADE, true, headers);

    return cpaResponse;
  } catch (err) {
    throw err;
  }
};

/**
 * Fetch product's price data from CSPP API
 * @param {string} storeId the store id
 * @param {object} gtinsByProductCode list of gtins separated by its product codes
 */
export const fetchCSPPriceData = async (storeId, gtinsByProductCode) => {
  if (!storeId || !gtinsByProductCode || isEmpty(gtinsByProductCode)) return null;

  let index = 0;
  let anchor;
  let priceData = [];
  const productCodes = Object.keys(gtinsByProductCode);

  try {
    while (index < productCodes.length) {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const priceUrlParamsObject = {
          locationId: storeId,
          gtins: gtinsByProductCode[productCodes[index]],
          includeSizes: 'false',
          csp: 'true',
        };

        let priceUrl = `${baseUrl(routerUrls.CSP_PRICE)}${endpoints.CSP_PRICE.url}${buildURLParams(priceUrlParamsObject)}`;
        if (anchor) {
          priceUrl = `${baseUrl(routerUrls.CSP_PRICE)}${anchor}`;
        }

        await generalAxiosRequest('GET', priceUrl, endpoints.CSP_PRICE)
          .then(response => {
            if (response && Array.isArray(response?.objects)) {
              priceData = [...priceData, ...response?.objects];
              anchor = response?.pages?.next;
            } else {
              priceData = [];
            }
          });

        if (!anchor) {
          break;
        }
      }
      index += 1;
    }
    return priceData;
  } catch (err) {
    console.log(err);
  }
};

/**
 * Fetch product's stock data from Store Stock V5 API
 * @param {string} storeId the store id
 * @param {array} gtins the list of gtins or style colors depending on isProductCodes param
 * @param {boolean} isProductCodes searching by productCodes instead of gtins
 */
export const fetchStoreStockData = async (storeId, gtins, isProductCodes) => {
  if (!storeId || !gtins || isEmpty(gtins)) return null;

  let index = 0;
  let stockData = [];
  const copyOfGtins = cloneDeep(gtins);
  const gtinsInChunk = Array.from({ length: Math.ceil(copyOfGtins.length / 10) }, (v, i) => copyOfGtins.slice(i * 10, i * 10 + 10));

  try {
    while (index < gtinsInChunk.length) {
      const storeStockV5ParamsObject = {
        storeId,
        [isProductCodes ? 'productCodes' : 'gtins']: gtinsInChunk[index],
      };

      const storeStockV5Url = `${baseUrl(routerUrls.DELEDGE)}${endpoints.STORE_STOCK_V5.url}${buildURLParams(storeStockV5ParamsObject)}`;
      await generalAxiosRequest('GET', storeStockV5Url, endpoints.STORE_STOCK_V5)
        .then(response => {
          if (response && Array.isArray(response.objects)) {
            if (isProductCodes) {
              const productObj = response.objects;
              productObj.map(p => p.productCode = gtins[index]);
              stockData = [...stockData, ...productObj];
            } else {
              stockData = [...stockData, ...response.objects];
            }
            index += 1;
          } else {
            stockData = [];
          }
        });
    }
    return stockData;
  } catch (err) {
    console.log(err);
  }
};
