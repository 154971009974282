import React, { useMemo, useState } from 'react';
import {
  bool, func, number, object, string,
} from 'prop-types';
import { Dialog } from '@mui/material';

import ConfirmationModal from './confirmationModal/ConfirmationModal';
import ScanInfo from './info/ScanInfo';
import ManagerApproval from './managerApproval/ManagerApproval';
import { scanTypesLabel } from '../../scanReportConstants';

import './DetailsWidgetContainer.css';

const DetailsWidgetContainer = ({
  accuracySubmissionThreshold, data, formatMessage, getMessage, isOffsiteEnabled, isScanNotProccessing, locale,
  nikeStoreRoles, scanProgress, SimDateTime,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [approvalAction, setApprovalAction] = useState('');

  const widget = useMemo(() => (
    <div className="details-widget">
      <h4 className="details-widget-title">
        {`${getMessage(scanTypesLabel[data.scanType])} ${getMessage('scanDetailsStatus')}`}
      </h4>
      <ManagerApproval
        accuracySubmissionThreshold={accuracySubmissionThreshold}
        data={data}
        getMessage={getMessage}
        formatMessage={formatMessage}
        isScanNotProccessing={isScanNotProccessing}
        nikeStoreRoles={nikeStoreRoles}
        scanProgress={scanProgress}
        setApprovalAction={setApprovalAction}
        setOpenModal={setOpenModal}
      />
      <ScanInfo
        data={data}
        getMessage={getMessage}
        isOffsiteEnabled={isOffsiteEnabled}
        locale={locale}
        SimDateTime={SimDateTime}
      />
    </div>
  ), [accuracySubmissionThreshold, data, getMessage, isOffsiteEnabled, isScanNotProccessing, locale, nikeStoreRoles, scanProgress]);

  return (
    <div className="details-widget-container widget" data-testid="details-widget-container">
      {widget}
      <Dialog
        open={openModal}
        fullScreen={false}
        maxWidth="md"
        fullWidth
      >
        <ConfirmationModal
          approvalAction={approvalAction}
          setOpenModal={setOpenModal}
          setApprovalAction={setApprovalAction}
        />
      </Dialog>
    </div>
  );
};

DetailsWidgetContainer.propTypes = {
  accuracySubmissionThreshold: number,
  data: object,
  formatMessage: func.isRequired,
  getMessage: func.isRequired,
  isOffsiteEnabled: bool,
  isScanNotProccessing: bool,
  locale: string,
  nikeStoreRoles: object.isRequired,
  scanProgress: number.isRequired,
  SimDateTime: object.isRequired,
};

DetailsWidgetContainer.defaultProps = {
  accuracySubmissionThreshold: 0,
  data: null,
  isOffsiteEnabled: false,
  isScanNotProccessing: false,
  locale: 'en-US',
};

export default DetailsWidgetContainer;
