/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
import { isEmpty } from 'lodash';
import { baseUrl, endpoints, routerUrls } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';

/**
 * Fetches the display scan data from the server.
 * @param {string} storeId - The ID of the store.
 * @param {string} storeNumber - The number of the store.
 * @param {string} sessionId - The ID of the session.
 * @param {string} endDateInclusive - The end date (inclusive) for the scan data.
 * @param {boolean} isEMEA - Indicates if the store is in EMEA region.
 * @param {boolean} eddEnabled - Indicates if EDD (Estimated Delivery Date) is enabled.
 * @returns {Promise<{ data: any, error: any }>} - The fetched data and any error that occurred during the request.
 */
export const fetchDisplayScan = async (storeId, storeNumber, sessionId, endDateInclusive, isEMEA, eddEnabled) => {
  let error = null;
  let data = null;

  if (!storeId || !sessionId) {
    return {};
  }

  const body = {
    storeId,
    storeNumber,
    aggregateBy: [
      'SCANNED',
    ],
    filters: [
      { name: 'sessionId', operator: 'EQUALS', value: sessionId },
    ],
    endDateInclusive: endDateInclusive.split('T')[0],
    isEMEA,
    eddEnabled,
  };
  const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.DISPLAY_SCAN.url}`;
  data = await generalAxiosRequest('POST', url, endpoints.DISPLAY_SCAN, true, null, body)
    .then(resp => resp)
    .catch(err => { error = err; });

  return { data, error };
};

/**
 * Fetch Scan Report data from Search Scans API
 * @param {object} variables the body used in scan report request
 */
export const fetchScanReportData = async (region, variables, cycleCountVariables) => {
  let error = null;
  let data = null;

  if (!region || isEmpty(variables)) {
    return {
      scans: [],
      cycleCounts: [],
      error,
    };
  }

  const body = {
    region,
    variables,
    cycleCountVariables,
  };

  const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.SCAN.url}`;
  data = await generalAxiosRequest('POST', url, endpoints.SCAN, true, null, body)
    .then(resp => resp)
    .catch(err => { error = err; });

  return data;
};

/**
 * Fetches the scan details data from Search Scan API
 * @param {string} sessionId the scan session ID
 * @param {string} endDate the selected end search date
 * @param {boolean} offsiteEnabled if offsite is enabled
 */
export const fetchScanDetailsData = async (sessionId, endDate, offsiteEnabled = false) => {
  if (!sessionId) return null;

  try {
    const params = `endDateInclusive=${endDate}&offsiteEnabled=${offsiteEnabled}`;
    const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.SCAN_DETAILS.url}/${sessionId}?${params}`;
    const response = await generalAxiosRequest('GET', url, endpoints.SCAN_DETAILS, true);

    return response;
  } catch (err) {
    throw err;
  }
};

/**
 * Upates the scan status of a RFID session
 * @param {string} sessionId the scan session ID
 * @param {string} status the scan status to be updated - SUBMITTED, SCANNING or CANCELLED
 */
export const updateScanStatus = async (sessionId, status) => {
  const url = `${baseUrl(routerUrls.RFID)}${endpoints.RFID_SESSIONS.url}/${sessionId}`;
  const headers = { 'Content-Type': 'application/merge-patch+json' };
  const body = { status };

  await generalAxiosRequest('PATCH', url, endpoints.RFID_SESSIONS, true, headers, body);
};
