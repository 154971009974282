import React, {
  useContext, useMemo, useRef, useState,
} from 'react';
import { array, string, func } from 'prop-types';
import MUIDataTable from 'mui-datatables';
import ReactToPrint from 'react-to-print';
import { camelCase } from 'lodash';
import { CSVLink } from 'react-csv';
import PrintIcon from '@mui/icons-material/Print';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import { filterSelectedData, buildDownloadData } from './adjustments.utils';
import PrinterPage from './print/PrinterPage';
import ExpandableRows from './print/ExpandableRows';

import { SimWebContext } from '../../context/SimWeb.provider';

import './AdjustmentsPage.css';

const baseIconStyles = {
  fontSize: '37px',
  color: '#757575',
  cursor: 'pointer',
  '&:hover': {
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    color: '#1976d2',
  },
};

const useStyles = makeStyles({
  printIcon: {
    ...baseIconStyles,
  },
  downloadIcon: {
    ...baseIconStyles,
  },
  downloadRightIcon: {
    ...baseIconStyles,
  },
});

const theme = createTheme(({
  components: {
    MUIDataTableToolbar: {
      styleOverrides: {
        actions: {
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
    },
  },
}));

const AdjustmentsTable = ({
  data, handlePrintingRequest, selectedPrintType,
}) => {
  const { isIOS, getMessage, SimDateTime } = useContext(SimWebContext);
  const [selectedItems, setSelectedItems] = useState([]);
  const classes = useStyles();
  const printRef = useRef();
  const csvLink = useRef();

  const buildDownloadHeaders = [
    {
      key: 'creationDate',
      name: 'creationDate',
      label: getMessage('date'),
    },
    { key: 'opId', name: 'athlete', label: getMessage('opId') },
    {
      key: 'type', label: getMessage('type'),
    },
    {
      // \xA0 is &nbsp; in JS.
      key: 'styleColor', label: `${getMessage('style')}\xA0\xA0\xA0${getMessage('color')}`,
    },
    {
      key: 'class', label: getMessage('class'),
    },
    {
      key: 'description', label: getMessage('description'),
    },
    {
      key: 'size', label: getMessage('size'),
    },
    {
      key: 'upc', label: getMessage('upc'),
    },
    {
      key: 'units', label: getMessage('units'),
    },
    {
      key: 'price', label: getMessage('price'),
    },
  ];

  const downloadIcon = (withDownload) => (
    <>
      <IconButton
        onClick={() => csvLink?.current?.link.click()}
        className={withDownload ? classes.downloadIcon : classes.downloadRightIcon}
        size="large"
      >
        <CloudDownloadIcon />
      </IconButton>
      <CSVLink
        headers={buildDownloadHeaders}
        data={buildDownloadData(data, selectedItems)}
        filename="Adjustments"
        ref={csvLink}
        target="_blank"
      />
    </>
  );

  const customToolbar = () => (
    <>
      {data?.length > 0 && (
        <div>
          {!isIOS && selectedPrintType !== 'dispatchNote' ? (
            <>
              <ReactToPrint
                trigger={() => (
                  <PrintIcon
                    className={classes.printIcon}
                    data-testid="print-icon"
                    style={{ width: '25px' }}
                  />
                )}
                content={() => printRef.current}
              />
              {downloadIcon(true)}
            </>
          ) : downloadIcon()}
          {!isIOS && (selectedPrintType === 'fiscalDoc' || selectedPrintType === 'dispatchNote') && (
            <PrintIcon
              className={classes.printIcon}
              data-testid="print-icon-fiscal"
              style={{ width: '25px' }}
              onClick={() => handlePrintingRequest(filterSelectedData(data, selectedItems))}
            />
          )}
        </div>
      )}
    </>
  );

  const searchFriendlyData = useMemo(() => data?.map(item => ({
    ...item?.tableData?.[0],
    totalUnits: item.totalUnits,
    searchHelper: JSON.stringify(item),
  })), [data]);

  const columns = () => {
    if (searchFriendlyData?.length > 0) {
      return [
        {
          name: 'creationDate',
          label: getMessage('date'),
          options: {
            customBodyRender: (value) => (
              <>{SimDateTime.toLocaleString(value, DATE_SHORT_WITH_APPENDED_ZEROS)}</>
            ),
          },
        },
        {
          name: 'creationDate',
          label: getMessage('time'),
          options: {
            customBodyRender: (value) => (
              <>{SimDateTime.toLocaleString(value, SimDateTime.TIME_SIMPLE)}</>
            ),
          },
        },
        {
          name: 'type',
          label: getMessage('type'),
          options: {
            customBodyRender: (value) => (
              <>{getMessage(camelCase(value?.toLowerCase()))?.toUpperCase() }</>
            ),
          },
        },
        {
          name: 'totalUnits',
          label: getMessage('units'),
          options: {
            setCellHeaderProps: () => ({
              'data-testid': 'columnStep',
            }),
          },
        },
        { name: 'employeeId', label: getMessage('recipientId') },
        { name: 'opId', label: getMessage('opId') },
        { name: 'searchHelper', options: { display: 'excluded' } },
      ];
    }
    return [];
  };

  const printTable = useMemo(() => (
    <div ref={printRef} data-testid="adjustment-table">
      <PrinterPage
        columns={columns()}
        getMessage={getMessage}
        data={filterSelectedData(data, selectedItems)}
      />
    </div>
  ), [selectedItems]);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MUIDataTable
            data={searchFriendlyData}
            columns={columns()}
            options={{
              filter: false,
              viewColumns: false,
              search: searchFriendlyData?.length > 0,
              customSearch: (searchQuery, currentRow) => {
                let isFound = false;
                currentRow.forEach(col => {
                  if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                    isFound = true;
                  }
                });
                return isFound;
              },
              pagination: false,
              selectableRows: searchFriendlyData?.length > 0 ? 'multiple' : 'none',
              print: false,
              download: false,
              textLabels: {
                body: {
                  noMatch: getMessage('noData'),
                },
              },
              onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                setSelectedItems(rowsSelected);
              },
              rowsSelected: selectedItems,
              customToolbarSelect: () => customToolbar(),
              customToolbar: () => customToolbar(),
              expandableRows: true,
              expandableRowsOnClick: true,
              expandableRowsHeader: data?.length > 0,
              renderExpandableRow: (rowData, { dataIndex }) => (
                <tr>
                  <td colSpan={rowData.length + 1}>
                    <ExpandableRows
                      data={data[dataIndex]}
                      getMessage={getMessage}
                    />
                  </td>
                </tr>
              ),
            }}
          />
        </ThemeProvider>
      </StyledEngineProvider>
      {printTable}
    </>
  );
};

AdjustmentsTable.propTypes = {
  data: array,
  handlePrintingRequest: func.isRequired,
  selectedPrintType: string,
};

AdjustmentsTable.defaultProps = {
  data: [],
  selectedPrintType: '',
};

export default AdjustmentsTable;
