import { DateTime } from 'luxon';

export const reconstructRCPPerformanceData = (data) => {
  const newData = {};
  if (data) {
    data.forEach(athleteTasks => {
      const tasks = {};
      athleteTasks.tasks.forEach(task => {
        const { minutes, units, uph } = task.details;
        tasks[task.time] = {
          minutes,
          units,
          uph,
        };
      });
      newData[athleteTasks.athleteId] = tasks;
    });
  }
  return newData;
};

/**
 * Grouping tasks by provided time unit
 * @param {object} data the store performance task data
 * @param {string} unit the time unit. e.g., hour or day
 */
export const reduceDataByTimeUnit = (data, unit) => {
  const newData = {};
  Object.keys(data).forEach((dateKey) => {
    const startOfTime = DateTime.fromISO(dateKey).startOf(unit).toUTC();

    if (!newData[startOfTime]) newData[startOfTime] = [];

    const newArray = [...newData[startOfTime], ...data[dateKey]];
    newData[startOfTime] = newArray;
  });

  return newData;
};

/**
 * Returns a number of fulfilled unit
 * @param items array of items
 * @param taskService task endpoint version
*/
export const getUnitsFulfilled = (items, taskService) => items.reduce((total, item) => {
  const units = (taskService?.toLowerCase() === 'v3' ? item.fulfillments[0]?.quantity : item.fulfillment?.quantityFulfilled) || 0;
  return units + total;
}, 0);

/**
 * Calculates and returns UPH
 * @param minutes number in minutes to completed a task
 * @param units number of units fulfilled
 */
export const calculateUnitPerHour = (minutes, units) => {
  if (minutes && units) return Math.round(60 * (units / minutes));
  return 0;
};

/**
 * Get Refill task performance details
 * @param task array of ProductRefillTask
 */
export const getTaskPerformanceDetails = (task, taskService) => {
  const taskStartedTime = task.creationDate;
  const taskCompletedTime = task.modificationDate;
  const taskDurationInMinutes = Math.ceil(DateTime.fromISO(taskCompletedTime).diff(DateTime.fromISO(taskStartedTime), 'minutes').minutes);
  const unitsFulfilled = getUnitsFulfilled(task.items, taskService);
  const uph = calculateUnitPerHour(taskDurationInMinutes, unitsFulfilled);

  return {
    time: taskCompletedTime,
    details: {
      uph,
      minutes: taskDurationInMinutes,
      units: unitsFulfilled,
    },
  };
};

/**
 * Extracts an athlete id from assignedAthleteId string, if it also contains store number, else return the id
 * @param {string} athletes the assignedAthleteId - ex: '123456' or 'USA;128;123456'
 */
export const getAthleteIdFromString = (athlete) => {
  if (!athlete) return null;

  const list = athlete.split(';');
  if (list.length > 1) return list[list.length - 1];
  return list[0];
};

/**
 * Returns a list of task performance by athlete
 * @param tasks array of ProductRefillTask
 * @param athlete athtlete id
 */
export const getTasksByAthlete = (tasks, athlete, taskService) => {
  const athleteTasks = tasks
    .filter(task => getAthleteIdFromString(task.assignedAthleteId) === athlete)
    .sort((a, b) => {
      const aDate = DateTime.fromISO(a.modificationDate);
      const bDate = DateTime.fromISO(b.modificationDate);
      return aDate - bDate;
    });

  return athleteTasks.map(task => getTaskPerformanceDetails(task, taskService));
};
