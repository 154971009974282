import React, { useMemo, useContext } from 'react';
import {
  node, func, number, bool,
} from 'prop-types';
import { DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CustomSpinner from '../CustomSpinner';

import { tutorialContext } from '../../context/Tutorial.provider';

/**
 * A custom header for the dialog that allows the user to flip throgh data without leaving the modal
 * @param {number} index - index of the data being viewed in the dialog
 * @param {function} setIsOpen - handles open/close of the dialog
 * @param {number} rowCount - number of items in parent datatable
 * @param {function} setIndex - function that update the current index
 * @param {node} nodeTitle - mode element for the title - customizable
 * @param {node} additionalNode - additonal header item that is required in dialog such as a button
 * @param {boolean} loading - current dialog data is loading
*/
const DialogHeader = ({
  index, setIsOpen, rowCount, setIndex, nodeTitle, additionalNode, loading,
}) => {
  const { startTutorial } = useContext(tutorialContext);

  const ellipses = (
    <div id="responsive-dialog-title">
      <CustomSpinner size="10px" color="#5CDAA8" loading margin="25px" />
    </div>
  );

  const rowsNavigation = useMemo(() => {
    if (loading && rowCount < 2) return ellipses;
    if (rowCount < 2 && !startTutorial) return null;

    const movePrevious = () => setIndex(index - 1);
    const moveNext = () => setIndex(index + 1);

    return (
      <>
        <IconButton
          disabled={index === 0}
          data-testid="modal-previous-button"
          onClick={() => movePrevious()}
          size="large"
        >
          <ChevronLeftIcon />
        </IconButton>
        <DialogTitle>
          <span id="responsive-dialog-title">
            {loading ? ellipses : nodeTitle}
          </span>
        </DialogTitle>
        <IconButton
          disabled={index === rowCount - 1}
          data-testid="modal-next-button"
          onClick={() => moveNext()}
          size="large"
        >
          <ChevronRightIcon />
        </IconButton>
      </>
    );
  }, [rowCount, setIndex, index, nodeTitle, loading]);

  return (
    <div className="modal-headerWrapper" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} data-testid="dialog-header">
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {rowsNavigation}
      </span>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {additionalNode}
        <IconButton
          data-testid="modalCloseButton"
          onClick={() => setIsOpen(false)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

DialogHeader.propTypes = {
  loading: bool,
  rowCount: number,
  index: number,
  setIndex: func.isRequired,
  setIsOpen: func.isRequired,
  nodeTitle: node,
  additionalNode: node,
};

DialogHeader.defaultProps = {
  loading: false,
  index: 0,
  nodeTitle: null,
  rowCount: 0,
  additionalNode: null,
};

export default DialogHeader;
