/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-useless-catch */
/* eslint-disable no-await-in-loop */
import React from 'react';
import { Grid } from '@mui/material';
import { isEmpty, union } from 'lodash';
import { Transfer } from '../../assets/Svgs/Transfer';
import { fetchStoreStockData } from '../../context/cpa/cpa.axios';
import getFormattedPrice from '../../utils/getFormattedPrice';
import { ShipmentTypes } from './inboundVisibility.contants';
import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { SimDateTime } from '../../utils/datetime';
import { buildColumnIndexMappings } from '../../utils/buildColumnIndexMappings';
import { inboundDivisionsFilter } from '../styleManagement/styleManagementConstants';
import DIVISIONS from '../../constants/divisions';

/**
 * Remove shipmentType and other data from download
 * @param {boolean} eddEnabled an indicator if EDD is enabled
 * @param {array} data the inbound visibility data
 * @param {array} columns the inbound visibility table columns
 * @param {string} country the country code
 * @param {boolean} isEMEA - Indicates if the store is in EMEA region.
 */
export const removeExtraDataFromDownload = (eddEnabled, data, columns, country, isEMEA) => {
  const toDownload = [];
  const mapping = buildColumnIndexMappings(columns);
  // eslint-disable-next-line array-callback-return
  data.map(item => {
    const newData = [];
    item.data.map((data, index) => {
      if (!isEMEA && index === mapping.lirp) return;
      if (index === mapping.shipmentType) return;
      if (country !== 'USA' && index === mapping.deliveryNumber) return;
      if (!eddEnabled && (index === mapping.edd || index === mapping.am_pm)) return;
      newData.push(data);
    });

    toDownload.push({
      index: undefined,
      data: newData,
    });
  });
  return toDownload;
};

/**
 * Remove shipmentType column and other table headers from download
 * @param {boolean} eddEnabled an indicator if EDD is enabled
 * @param {array} columns the inbound visibility table columns
 * @param {string} country the country code
 * @param {boolean} isEMEA - Indicates if the store is in EMEA region.
 */
export const removeExtraColumnFromDownload = (eddEnabled, columns, country, isEMEA) => {
  const newColumns = [];

  columns.map((item) => {
    if (!isEMEA && item.name === 'lirp') return;
    if (item.name === 'shipmentType') return;
    if (country !== 'USA' && item.name === 'deliveryNumber') return;
    if (!eddEnabled && (item.name === 'edd' || item.name === 'am_pm')) return;
    newColumns.push(item);
  });

  return newColumns;
};

export const getRowData = (rows, selectableRows) => {
  const finalDropZoneRowData = [];
  const getSelectedRowIndices = selectableRows.data.map(item => item.dataIndex);
  getSelectedRowIndices.forEach((item) => { finalDropZoneRowData.push(rows[item]); });
  return finalDropZoneRowData;
};

/**
  * A util for .com availibility for each product in the inbound visibility modal
  * @param {array} dialogData - data from getInboundVisibilityData query per row clic
  * @param {number} index - index of the item in the dialog array
  *  @param {string} merchGroup - comes from storeViews ex - 'US'
  * @param {function} setIndex - handles modifying index to update the dialog data
*/
export const fetchDotComAvailability = async (dialogData, index, merchGroup, setIndex) => {
  if (!merchGroup || !dialogData) return null;

  const productCodes = [];
  const newData = dialogData;

  dialogData?.map(data => productCodes.push(data?.styleColor));

  try {
    const urlParams = `?styleColor=${productCodes[index]}&merchGroup=${merchGroup}`;
    const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.DOTCOM_AVAILABILITY.url}${urlParams}`;
    await generalAxiosRequest('GET', url, endpoints.DOTCOM_AVAILABILITY)
      .then(response => {
        if (newData[index]) {
          newData[index].available = response?.objects?.some(obj => (obj?.available === true)) ? 'YES' : 'NO';
          setIndex(index + 1);
        }
      });
  } catch (err) {
    throw err;
  }
  return newData;
};

/**
  * A temp util to pull the lastest SOH values for a given product
  * modal detailss
  * @param {string} merchGroup - comes from storeViews ex - 'US'
  * @param {array} dialogData - data from getInboundVisibilityData query per row click
  * @param {number} index - index of the item in the dialog array
  * @param {function} setLoading - handles modifying loading state for modal
  * @param {string} storeId - store id number
  * @param {boolean} rfidEnabled - store with RFID enabled tagging
  * @param {boolean} isOffsiteEnabled - if store has offsite enabled
*/
export const getUpdatedSOHData = async (merchGroup, dialogData, index, setLoading, storeId, rfidEnabled, isOffsiteEnabled) => {
  if (!merchGroup || !dialogData) return null;

  const newData = dialogData;
  const productCodes = [];
  let storeStockData = null;

  dialogData?.map(data => productCodes.push(data?.styleColor));

  if (productCodes?.length > 0 && productCodes?.[index]) {
    storeStockData = await fetchStoreStockData(storeId, [productCodes?.[index]], true);

    const sohobj = {
      offsite: 0,
      salesFloor: 0,
      soh: 0,
      stockRoom: 0,
    };

    storeStockData?.map(stock => {
      if (stock.productCode === newData[index].styleColor && stock.gtin === newData[index].gtin) {
        const { physicalStock, fiscalStock, sellableStock } = stock;

        sohobj.offsite = isOffsiteEnabled ? physicalStock?.segmentation?.offSiteQuantity : sellableStock?.segmentation?.offSiteQuantity;
        sohobj.salesFloor = rfidEnabled ? physicalStock?.segmentation?.salesFloorQuantity : sellableStock?.segmentation?.salesFloorQuantity;
        sohobj.soh = rfidEnabled ? physicalStock?.quantity : fiscalStock?.quantity;
        sohobj.stockRoom = rfidEnabled ? physicalStock?.segmentation?.stockRoomQuantity : sellableStock?.segmentation?.stockRoomQuantity;
        newData[index].soh = sohobj;
      }
    });
    setLoading(false);
  }
  return newData;
};

/**
  * a function that checks and replaces the filters added to the POST call
  * @param {Array} filters - the filters array passed into the POST call
  * @param {object} filterBy - the object we're filtering by - ex {name: 'category', operator: 'EQUALS', values: []}
  * @param {Function} setFilteredData - sets the new filter values
*/
const setFilteredValues = (filters, filterBy, setFilteredData) => {
  // eslint-disable-next-line no-unused-expressions
  filters && Object.keys(filters).map((key) => {
    const value = filters[key];
    if (value.length > 1) {
      filters[key].pop();
      // eslint-disable-next-line no-param-reassign
      filters = {
        ...filters,
        [key]: [...filters[key], filterBy],
      };
      setFilteredData(filters);
    }
    return filters;
  });
};

/**
 * return the short-hand form of the processing type
 * @param {string} type - the processing type of the shipment
 */
// eslint-disable-next-line no-nested-ternary, no-confusing-arrow
const getProcessingType = type => (type && type.toUpperCase() === 'REPLENISHMENT') ? 'RP' : (type && type.toUpperCase() === 'LOADIN') ? 'LI' : '-';

/**
 * A helper function to extract PO details from product shipment data
 * @param {object} item - the product shipment object
 * @param {String} locale - unique value to help us get the accurate date val
 * @param {String} currencyCode - unique value to help get the currency val
 */
const getPurchaseOrderDetails = (item, locale, currencyCode) => ({
  poNumber: item?.poNumber,
  cartonNumber: item?.cartonNumber || '-',
  styleColor: item?.productCode || '-',
  description: item?.description || '-',
  category: item?.categoryInfo?.retailCategory || '-',
  gender: item?.categoryInfo?.genderAge || '-',
  size: item?.size || '-',
  unitsExpected: item?.unitCount || '-',
  soh: item?.stockOnHand || null,
  price: getFormattedPrice(item?.price, locale, currencyCode),
  bin: item?.binInfo || '-',
  lirp: getProcessingType(item?.processingType),
  gtin: item?.gtin,
  status: item?.status || '-',
});

/**
  * Gets the data for the summary table with data visuals for Inbound Visibility
  * @param {String} filter - a selectd value to filter - ex: category
  * @param {Array} labels - label options to filter by - ex: ["Gender", "Category", "LI/RP"]
  * @param {Function} setFilterBy - sets the value the user is selecting to filter by - ex: category
  * @param {Object} inboundVisibilityfilters - predetermined filter values that we add to the
  * filters when the user selected on the toggle
  * @param {Object} filters - all of the filters contained for each division type - gets passed into the query
  * @param {Function} setFilteredData - handles setting the filters obj for the query calls
  * @param {String} setFilteredByName - handles setting the string value of the item we are filtering by
*/
// eslint-disable-next-line import/prefer-default-export
export const getFilteredData = (
  filter,
  labels,
  setFilterBy,
  inboundVisibilityfilters,
  filters,
  setFilteredData,
  setFilteredByName,
) => {
  setFilterBy(filter);
  const { genderAge, category, processingType } = inboundVisibilityfilters;
  const localFilters = filters;
  switch (labels.indexOf(filter)) {
    case 0:
      setFilteredValues(localFilters, genderAge, setFilteredData);
      setFilteredByName(genderAge.name);
      break;
    case 1:
      setFilteredValues(localFilters, category, setFilteredData);
      setFilteredByName(category.name);
      break;
    case 2:
      setFilteredValues(localFilters, processingType, setFilteredData);
      setFilteredByName(processingType.name);
      break;
    default:
  }
};

export const getShipmentTypeLabel = (shipmentType) => {
  switch (shipmentType) {
    case ShipmentTypes.Xsn:
      return (
        <Grid container direction="row">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Transfer />
          </div>
        </Grid>
      );
    default:
      return shipmentType;
  }
};

export const tableSort = (data, dataIndex, sort) => {
  const sortedValue = [...data];

  const sortby = sort === 'desc' ? -1 : 1;
  sortedValue?.sort((a, b) => ((a[dataIndex] < b[dataIndex]) ? sortby * 1 : sortby * -1));

  return sortedValue;
};

export const mergeOrdersBasedOnStyleColors = itemDetails => itemDetails.reduce((acc, current) => {
  const squishedObj = {};
  const duplicate = acc.find((item) => {
    if (item.styleColor === current.styleColor) {
      // eslint-disable-next-line no-param-reassign
      item.unitsExpected += current.unitsExpected;
    }
    return item.styleColor === current.styleColor;
  });

  if (!duplicate) {
    squishedObj.poNumber = current.poNumber;
    squishedObj.price = current.price;
    squishedObj.styleColor = current.styleColor;
    squishedObj.description = current.description;
    squishedObj.unitsExpected = current.unitsExpected;
    return acc.concat([squishedObj]);
  }
  return acc;
}, []);

/**
 * Takes the inbound visibility report data and organize it by PO numbers
 * @param {array} data - the inbound visibility report data
 * @param {String} locale - unique value to help us get the accurate date val
 * @param {String} currencyCode - unique value to help get the currency val
 * @param {boolean} eddEnabled - is edd enabled in the store
 */
export const constructTableData = (data, locale, currencyCode, eddEnabled) => {
  const newData = [];

  data?.forEach((item) => {
    const edd = item?.estimatedDeliveryDate ? SimDateTime.toDateTime(item.estimatedDeliveryDate)?.toISODate() : '-';
    const am_pm = SimDateTime.toAmPm(item?.estimatedDeliveryDate); // eslint-disable-line camelcase
    const index = newData?.findIndex(_ => _?.poNumber === item?.poNumber && _?.docNumber === item?.docNumber);
    const poDetails = getPurchaseOrderDetails(item, locale, currencyCode);
    const [carrierCode, trackingNumber] = !item?.docNumber ? [] : [...item.docNumber.split('-')];

    if (index === -1) {
      newData.push({
        carrierCode: carrierCode ?? (item?.carrier ?? '-'),
        carton: 1,
        deliveryNumber: item?.deliveryNumber || '-',
        details: [poDetails],
        divisionArray: [DIVISIONS.getMappedValue(item?.categoryInfo?.division).toUpperCase() || '-'],
        docNumber: item?.docNumber || '-',
        edd,
        am_pm,
        genderArray: [item?.categoryInfo?.genderAge || '-'],
        lirp: getProcessingType(item?.processingType),
        poNumber: item?.poNumber,
        status: item?.status || '-',
        shipDate: item?.shipDate || '-',
        shipmentType: getShipmentTypeLabel(item?.shipmentType || '-'),
        shipmentTypeFilter: item?.shipmentType || '-',
        trackingNumber: trackingNumber ?? (item?.docNumber ?? '-'),
        units: item?.unitCount || '-',
      });
    } else {
      // eslint-disable-next-line max-len
      if (newData[index]?.details && !newData[index]?.details.find(_ => _?.cartonNumber === item?.cartonNumber)) newData[index].carton += 1;
      newData[index].units += item.unitCount;
      newData[index]?.details.push(poDetails);
      newData[index].genderArray.push(item?.categoryInfo?.genderAge || '-');
      newData[index].divisionArray.push(DIVISIONS.getMappedValue(item?.categoryInfo?.division).toUpperCase() || '-');
    }
  });

  // flatten any category info
  newData.forEach(item => {
    item.gender = [...new Set(item.genderArray)].join(', ');
    item.division = [...new Set(item.divisionArray)].join(', ');
  });

  newData.sort((a, b) => {
    const nameA = (eddEnabled ? a.edd : a.shipDate).toUpperCase();
    const nameB = (eddEnabled ? b.edd : b.shipDate).toUpperCase();

    if (nameA === nameB) {
      return a.poNumber - b.poNumber;
    }
    return nameA > nameB ? 1 : -1;
  });

  return newData;
};

/**
 * Takes the inbound visibility purchase order details data and organize it by Style Colors
 * @param {array} data - the purchase order details data
 */
export const constructPurchaseOrderDetailsByStyleColors = (data) => {
  const newData = [];
  data.forEach((item) => {
    const index = newData.findIndex(_ => _.styleColor === item.styleColor);
    if (index === -1) {
      newData.push({
        styleColor: item.styleColor,
        description: item.description,
        price: item.price,
        unitsExpected: item.unitsExpected,
        bin: item.bin,
        available: item.available,
        gtin: item?.gtin,
      });
    } else {
      newData[index].unitsExpected += item.unitsExpected;
      newData[index].bin = union(newData[index].bin, item.bin);
    }
  });
  return newData;
};

/**
 * Get the total number of cartons in the purchase order
 * @param {array} data - an array of purchase order details
 */
export const getCartonNumber = (data) => {
  const list = [];
  data.forEach((item) => {
    if (!list.find(_ => _ === item.cartonNumber)) list.push(item.cartonNumber);
  });
  return list.length;
};

/**
 * A helper function to get the RFID SOH segmentations
 * @param {boolean} rfidEnabled if true, return physical stock segments
 * @param {boolean} isOffsiteEnabled if true, return offsite stock segment
 * @param {object} soh the salesFloor, stockRoom and offsite SOH object
 */
export const getSOH = (rfidEnabled, isOffsiteEnabled, soh) => {
  const salesFloor = soh?.salesFloor || 0;
  const stockRoom = soh?.stockRoom || 0;
  const offsite = soh?.offsite || 0;
  if (rfidEnabled) return `${salesFloor} / ${stockRoom}${isOffsiteEnabled ? ` / ${offsite}` : ''}`;

  return soh?.soh?.toString() || '-';
};
/**
 * A helper function to get the totalSOH if RFID is enabled
 * @param {boolean} rfidEnabled if true, return physical stock segments
 * @param {boolean} isOffsiteEnabled if true, return offsite stock segment
 * @param {object} soh the salesFloor, stockRoom and offsite SOH object
 */
export const getTotalSOH = (rfidEnabled, isOffsiteEnabled, soh) => {
  if (!soh || isEmpty(soh)) return '-';

  const salesFloor = soh.salesFloor || 0;
  const stockRoom = soh.stockRoom || 0;
  const offsite = soh.offsite || 0;

  if (rfidEnabled) return salesFloor + stockRoom + (isOffsiteEnabled ? offsite : 0);

  return soh.soh || 0;
};

/**
 * A helper function to return the number if it is a number then take the floor to make it an integer, otherwise return 0
 * @param {any} number the number of the product
 */
const getNumberOrReturnZero = (number) => (typeof number === 'number' && !Number.isNaN(number) ? Math.floor(number) : 0);
/**
 * A helper function to get store stock data by style-color and return the total SOH of each style-color
 * @param {string} storeId the store ID
 * @param {array} selectedRowData a list of selected table rows data
 * @param {boolean} rfidEnabled if true, return physical stock segments
 */
export const getCSVDownloadData = async (storeId, selectedRowData, rfidEnabled) => {
  const styleColorList = [];
  const sohByStyleColor = {};

  selectedRowData?.map(row => row?.details?.map(detail => {
    if (styleColorList.indexOf(detail.styleColor) === -1) styleColorList.push(detail.styleColor);
  }));

  await Promise.all(styleColorList?.map(async item => {
    await fetchStoreStockData(storeId, item, true).then(resp => {
      const totalSOH = resp?.map(stock => {
        const { physicalStock, fiscalStock } = stock;
        return rfidEnabled ? physicalStock?.quantity : fiscalStock?.quantity;
      }).reduce((acc, current) => acc + current, 0);
      sohByStyleColor[item] = totalSOH;
    });
  }));

  return sohByStyleColor;
};

/**
 * Creates a list of items for dropzones CSV download
 * @param {array} rows - table rows
 * @param {array} selectedRows - selected rows index object
 * @param {object} selectedStyleColorSOH - the total SOH by style colors
 * @param {boolean} isPRO - an indicator to display PRO Number or DOC Number header
 * @param {boolean} useSeparatedPRONumber - an indicator to display separated PRO Number and Carrier Code
 * @param {boolean} isEMEA - an indicator if the store is in EMEA region
 * @param {boolean} isUSA - an indicator if the store is in USA
 */
export const buildDropZonesListCSVData = (rows, selectedRows, selectedStyleColorSOH, isPRO, useSeparatedPRONumber, isEMEA, isUSA) => {
  const csvData = [];
  const newObject = {};
  let totalCartons = 0;
  const proNumberList = [];
  const poNumberList = [];
  const extraEmptySpace = useSeparatedPRONumber ? ['', ''] : [''];

  if (!rows || !selectedRows || !selectedStyleColorSOH) return csvData;

  const selectedRowData = selectedRows?.data?.map(row => row.dataIndex).map(index => rows[index]);
  if (!selectedRowData) return csvData;

  // restructure selected rows data by divisions, then by style-colors
  selectedRowData?.forEach(row => {
    totalCartons += getNumberOrReturnZero(row?.carton);
    row?.details?.forEach(detail => {
      const division = DIVISIONS.getMappedValue(row?.division).toUpperCase();
      if (row.docNumber !== '-' && proNumberList.indexOf(row.docNumber) === -1) proNumberList.push(row.docNumber);
      if (row.poNumber !== '-' && poNumberList.indexOf(row.poNumber) === -1) poNumberList.push(row.poNumber);

      if (!newObject[division]) newObject[division] = [];

      const styleColor = detail?.styleColor;
      // make call to current SOH

      if (!newObject[division][styleColor]) {
        newObject[division][styleColor] = {
          docNumber: [row.docNumber],
          carrierCode: [row.carrierCode],
          trackingNumber: [row.trackingNumber],
          ...(isUSA ? { deliveryNumber: row.deliveryNumber } : {}),
          poNumber: [row.poNumber],
          bin: detail?.bin?.join(', '),
          styleColor: detail.styleColor,
          description: detail.description,
          gender: detail.gender,
          category: detail.category,
          size: [detail.size],
          price: detail.price,
          unitsExpected: getNumberOrReturnZero(detail.unitsExpected),
          soh: getNumberOrReturnZero(selectedStyleColorSOH[detail.styleColor]),
          status: detail?.status,
          ...(isEMEA ? { lirp: detail.lirp } : null),
        };
      } else {
        if (!newObject[division][detail.styleColor].size.includes(detail.size)) {
          newObject[division][detail.styleColor].size.push(detail.size);
        }
        newObject[division][detail.styleColor].unitsExpected += getNumberOrReturnZero(detail.unitsExpected);
        if (row.docNumber !== '-' && newObject[division][detail.styleColor].docNumber.indexOf(row.docNumber) === -1) {
          newObject[division][detail.styleColor].docNumber.push(row.docNumber);
        }
        if (row.poNumber !== '-' && newObject[division][detail.styleColor].poNumber.indexOf(row.poNumber) === -1) {
          newObject[division][detail.styleColor].poNumber.push(row.poNumber);
        }
      }
    });
  });

  // eslint-disable-next-line no-nested-ternary
  const proNumberHeaders = useSeparatedPRONumber ? ['Carrier Code', 'PRO Number'] : isPRO ? ['PRO Number'] : ['DOC Number'];
  const headerRow = [
    ...proNumberHeaders,
    ...(isUSA ? ['Delivery Number'] : []),
    'PO Number',
    'Bin',
    'Style-Color',
    'Description',
    'Gender',
    'Category',
    'Price',
    'Units Expected',
    'Style-Color SOH',
    'Status',
    ...(isEMEA ? ['LI/RP'] : []),
    'Total (Units Expected + Style-Color SOH)',
  ];

  // summary section
  csvData.push(['', '', '', '', 'SUMMARY']);
  csvData.push(['PROs:', proNumberList.length, '', '', 'POs:', poNumberList.length, '', '', 'Cartons:', totalCartons]);
  csvData.push([]);

  // divisions section
  ['FOOTWEAR', 'APPAREL', 'EQUIPMENT'].forEach(division => {
    if (newObject[division]) {
      csvData.push(['', '', '', '', division]);
      csvData.push(headerRow);

      let divisionUnitsExpectedTotal = 0;
      const itemList = [];

      Object.keys(newObject[division]).forEach(styleColor => {
        const { size, docNumber, carrierCode, trackingNumber, ...item } = newObject[division][styleColor];
        divisionUnitsExpectedTotal += item.unitsExpected;

        const docNumberData = useSeparatedPRONumber ? {
          carrierCode: carrierCode?.join(', '),
          trackingNumber: trackingNumber?.join(', '),
        } : { docNumber: docNumber.join(', ') };

        const itemWithTotal = {
          ...docNumberData,
          ...item,
          poNumber: item.poNumber.join(', '),
          total: item.unitsExpected + item.soh,
        };
        itemList.push(Object.values(itemWithTotal));
      });

      // sort the list by gender and merge them into csvData array
      itemList.sort((a, b) => ((a[4] < b[4]) ? -1 : 1)).forEach(item => csvData.push(item));
      csvData.push([...extraEmptySpace, '', '', '', '', '', '', 'Total Units', divisionUnitsExpectedTotal]);
      csvData.push([]);
    }
  });

  return csvData;
};

export const getDefaultVariables = (store, storeId, isEMEA, region, pageSearchText, filters, eddEnabled, startDate, endDate) => {
  const startOfDay = SimDateTime.startOfDay(startDate);
  const endOfDay = SimDateTime.endOfDay(endDate);
  const isDocNumberEmpty = filters.some(filter => filter.name === 'docNumber' && filter.values[0] === '');

  const defaultVariables = {
    storeName: store,
    storeId,
    region,
    textQuery: pageSearchText,
    // The getInboundVisibility endpoint does not support pagination, using a swag value to pull enough data.
    // 150 is an approximate factor for the number of rows per shipDate, used to get back more data as
    // more shipped dates are selected, which is then summed.
    count: 1000,
    filters: [...filters,
      ...((filters.length === 0 || isDocNumberEmpty)
        ? [
          {
            name: eddEnabled ? 'edd' : 'shipDate',
            operator: 'GTE',
            values: [eddEnabled ? startOfDay.toUTC() : startOfDay.toISODate()],
          },
          {
            name: eddEnabled ? 'edd' : 'shipDate',
            operator: 'LTE',
            values: [eddEnabled ? endOfDay.toUTC() : endOfDay.toISODate()],
          }]
        : []),
      ...(isEMEA
        ? [
          {
            name: 'status',
            operator: 'EQUALS',
            values: ['PLANNED', 'SHIPPED', 'CANCELLED'],
          },
        ]
        : []),
    ],
    firstPageOnly: false,
  };

  return defaultVariables;
};

/**
  * gets inbound variables to be posted to the inbound visilbity endpoint
  * @param {string} storeName - store name
  * @param {string} storeId - store id
  * @param {string} region - region
  * @param {string} county - country
*/
export const getInboundVariables = (storeName, storeId, region, isEMEA, eddEnabled) => {
  if (!storeName || !storeId || !region) return null;

  const today = SimDateTime.now();
  const startDate = eddEnabled ? SimDateTime.startOfDay(today).toUTC() : SimDateTime.startOfDay(today).minus({ day: 7 }).toISODate();
  const endDate = eddEnabled ? SimDateTime.endOfDay().plus({ days: 6 }).toUTC() : SimDateTime.endOfDay().toISODate();
  const variables = {
    storeName,
    storeId,
    region,
    textQuery: '',
    count: 1000,
    filters: [
      inboundDivisionsFilter,
      {
        name: eddEnabled ? 'edd' : 'shipDate',
        operator: 'GTE',
        values: [`${startDate}`],
      },
      {
        name: eddEnabled ? 'edd' : 'shipDate',
        operator: 'LTE',
        values: [`${endDate}`],
      },
      ...(isEMEA
        ? [
          {
            name: 'status',
            operator: 'EQUALS',
            values: ['PLANNED', 'SHIPPED', 'CANCELLED'],
          },
        ]
        : []),
    ],
    getSummary: false,
    firstPageOnly: false,
  };
  return variables;
};

/**
  * return inbound visiblity data as product object
  * @param {Array} data - inbound data
  * @param {Boolean} getUnitByUPC - additional unit count by upc
*/
export const getInboundUnits = (data, getUnitByUPC = false) => {
  const products = {};

  data?.forEach(item => {
    const units = item?.unitCount || 0;
    if (item?.productCode && !getUnitByUPC) {
      if (!products[item.productCode]) {
        products[item.productCode] = {};
        products[item.productCode].units = units;
      } else {
        products[item.productCode].units += units;
      }
    }

    if (getUnitByUPC) {
      if (item?.gtin) {
        if (!products[item.gtin]) {
          products[item.gtin] = {};
          products[item.gtin].units = units;
        } else {
          products[item.gtin].units += units;
        }
      }
    }
  });
  return products;
};

/**
 * Util that takes state/utils/configs & handles setting/changing steps in the turorial when clicking through the steps
 * @param {boolean} startTutorial - checks if we're in tutorial mode
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {boolean} restartTutorial - a value used to cancel out any state since the tutorial is no longer active
 * @param {boolean} allShipDatesSelected - a value that represents all the checkboxes in the table are clicked
 * @param {boolean} singleShipDateSelected - a value that represents the first checkbox in the table is clicked
 * @param {boolean} printDialogOpen - a value that represents the print dialog modal is open and mounted
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 * @param {boolean} isOpen - a value that represents the table row/shipdate was selected - meaning the modal was mounted
 * @param {boolean} isDropZonesEnabled - a config that lets us know how to handle any dropzone steps
 * @param {func} setIsOpen - a function that opens/closes the modal
 * @param {func} setBackWasClicked - a function that handles the value of isbackClicked
 * @param {func} setPrintDialogOpen - a function that handles the printDialogOpen value to unmount/mount the modal
 * @param {func} setStepIndex - a function that handles setting the step index
 */
export const setInboundTutorialSteps = (
  startTutorial, stepIndex, restartTutorial, allShipDatesSelected, singleShipDateSelected,
  printDialogOpen, isbackClicked, isOpen, isDropZonesEnabled, setIsOpen,
  setBackWasClicked, setPrintDialogOpen, setStepIndex, setTimeOutOnTutorial,
) => {
  const listOfCheckboxes = document.querySelectorAll('table td input');
  const dataVisuals = document.querySelectorAll('[data-testid="reportSummary_dataVisualizations-container"]');
  if (startTutorial) {
    if (stepIndex === 7 && dataVisuals.length >= 1) {
      setTimeOutOnTutorial(500, 7);
    }
    if (stepIndex === 8 && allShipDatesSelected && isbackClicked) {
      document.querySelector('table input')?.click();
    }
    if (stepIndex === 9 && !allShipDatesSelected && !isbackClicked) {
      document.querySelector('table input')?.click(); // unselect all
    }
    if (stepIndex === 9 && isbackClicked) {
      listOfCheckboxes[0].click();
      setTimeout(() => {
        document.querySelector('table input')?.click();
      }, 200);
    }
    if (stepIndex === 10) {
      document.querySelector('table input')?.click();
      setTimeout(() => {
        listOfCheckboxes[0].click();
      }, 200);
    }
    if (stepIndex === 11) {
      document.querySelector('table input')?.click();
      setTimeout(() => {
        listOfCheckboxes[0].click();
        listOfCheckboxes[1].click();
      }, 200);
    }
    if (isDropZonesEnabled) { // only for dropzone stores
      if (stepIndex === 12 && allShipDatesSelected && isbackClicked) {
        document.querySelector('table input')?.click();
        setTimeout(() => {
          document.querySelector('table td input')?.click();
        }, 500);
      }
      if (isbackClicked && printDialogOpen && stepIndex === 13) {
        setPrintDialogOpen(false);
      }
      if (isbackClicked && !printDialogOpen && stepIndex === 14) {
        setBackWasClicked(false);
        setTimeout(() => {
          listOfCheckboxes[0].click();
          listOfCheckboxes[1].click();
        }, 200);
      }
      if (stepIndex === 14 && !printDialogOpen) { // opens dropzone modal
        setPrintDialogOpen(true);
        document.querySelector('[title="Create Dropzones"]')?.click();
        setTimeout(() => {
          setStepIndex(14);
        }, 500);
      }
      if (stepIndex === 15 && printDialogOpen) {
        setTimeout(() => {
          listOfCheckboxes[0].click();
          listOfCheckboxes[1].click();
        }, 200);
        setPrintDialogOpen(false);
      }
      if ((stepIndex === 15 && isbackClicked)) {
        setIsOpen(false);
        setTimeout(() => {
          listOfCheckboxes[0].click();
          listOfCheckboxes[1].click();
        }, 500);
        setTimeout(() => {
          setStepIndex(15);
        }, 500);
      }
      if (stepIndex === 16 && !isOpen) { // close the print dropzone
        document.querySelector('[data-testid="MUIDataTableBodyRow-0"]')?.click(); // click the single row
        setTimeout(() => {
          setStepIndex(16);
        }, 500);
      }
      if (stepIndex === 17 && isbackClicked) {
        setIsOpen(true);
        document.querySelector('[data-testid="MUIDataTableBodyRow-0"]')?.click(); // click the single row
        setTimeout(() => {
          setStepIndex(17);
        }, 500);
      }
    }
    // need to check on this
    if (!isDropZonesEnabled) {
      if (stepIndex === 12 && isbackClicked) {
        setTimeout(() => {
          listOfCheckboxes[0].click();
          listOfCheckboxes[1].click();
        }, 100);
        setTimeout(() => {
          setStepIndex(12);
        }, 500);
        setBackWasClicked(false);
      }
      if (stepIndex === 13) {
        setTimeout(() => {
          listOfCheckboxes[0].click();
          listOfCheckboxes[1].click();
        }, 200);
      }
      if (stepIndex === 13 && isbackClicked) {
        setIsOpen(false);
      }
      if (stepIndex === 14 && !isOpen) {
        setIsOpen(true);
        document.querySelector('[data-testid="MUIDataTableBodyRow-0"]')?.click(); // click the single row for modal access
        setTimeout(() => {
          setStepIndex(14);
        }, 500);
      }
    }
  }
  // clear state & reset tutorial
  if (restartTutorial) {
    if (printDialogOpen) setPrintDialogOpen(false);
    if (isOpen) setIsOpen(false);
    if (allShipDatesSelected && singleShipDateSelected) document.querySelector('table input').click(); // unselect all
    if (!allShipDatesSelected && listOfCheckboxes[0]?.checked) listOfCheckboxes[0].click(); // unselect first row
    if (!allShipDatesSelected && listOfCheckboxes[1]?.checked) listOfCheckboxes[1].click();
  }
};
